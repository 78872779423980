<template>
    <CContainer class="d-flex align-items-center mt-2">
      <CRow class="justify-content-center col-md-12">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-6"
          body-wrapper>
          <div>
            <h3>checkout success</h3>
          </div>
        </CCard>
      </CRow>
    </CContainer>
  </template>
  
  <script>
  
  export default {
    name: 'Login',
    data() {
      return {
        title: '',
        msgError: '',
        msgSuccess: '',
        alert: '',
        delayAlert: 3,
        loadingButton: false
      }
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
  }
  </script>
  